class ManageExtendedProfileQuestion {

    //Suggests a key based on either a client or project name
    static topform(component) {
        return [
          {
            id: 1,
            name: `${component.$t("SETUP.EXTENDED_PROFILING.GENERAL")}`,
            errors: component.tab1Errors,
            sections: [
              { 
                rows: [
                  {
                    components: [
                      {
                        name: "InputGenericDropdown",
                        properties: {
                          class: "col-xl-4 px-5 mb-7",
                          id: 'input-system-answer-type',
                          label: `${component.$t("FORMS.INPUTS.ANSWER_TYPE")}`,
                          model: component.$v.form.system_answer_type_id,
                          options: component.computedSystemAnswerTypes,
                          optionLabel: "name",
                          errorMessage: `${component.$t("FORMS.ERROR.ANSWER_TYPE")}`,
                        }
                      }
                    ]
                  },
                  {
                    components: [
                      {
                        name: "InputGenericRichText",
                        properties: {
                          class: "col-xl-12 px-5",
                          id: 'input-extended-profile-question',
                          label: `${component.$t("FORMS.INPUTS.EXTENDED_PROFILE_QUESTION")}`,
                          model: component.$v.form.question,
                          error: `${component.$t("FORMS.GENERIC_ERRORS.REQUIRED")}`,
                          submissionStates: component.submissionStates
                        }
                      }
                    ]
                  },
                  {
                    components: [
                      {
                        name: "IntegratedDropzone",
                        on: {
                          asset_reservation_created: (value) => { component.$refs["extended-profile-question-topform"].$emit('asset_reservation_created', value) },
                          asset_reservation_deleted: (value) => { component.$refs["extended-profile-question-topform"].$emit('asset_reservation_deleted', value) },
                          remove_project_asset: (value) => { component.$refs["extended-profile-question-topform"].$emit('remove_project_asset', value) },
                        },
                        properties: {
                          class: "col-xl-12",
                          assetReservation: component.assetReservationData,
                          idx: 1,
                          projectAssets: component.extendedProfileQuestion.project_assets,
                          wholeWidth: true,
                          removable: true
                        }
                      }
                    ]
                  },
                  {
                    components: [
                      {
                        name: "InputGenericCheckbox",
                        properties: {
                          class: "col-xl-12 px-5 mb-0",
                          id: 'input-display-answer',
                          label: `${component.$t("FORMS.INPUTS.EXTENDED_PROFILE_DISPLAY")}`,
                          model: component.$v.form.display,
                          submissionStates: component.submissionStates
                        }
                      },
                      {
                        name: "InputGenericCheckbox",
                        properties: {
                          class: "col-xl-12 px-5 mb-0",
                          id: 'input-required',
                          label: `${component.$t("FORMS.INPUTS.EXTENDED_PROFILE_REQUIRED")}`,
                          model: component.$v.form.required,
                          submissionStates: component.submissionStates
                        }
                      },
                      {
                        show: component.form.system_answer_type_id == 1,
                        name: "InputGenericCheckbox",
                        properties: {
                          class: "col-xl-12 px-5 mb-0",
                          id: 'input-allow-media',
                          label: `${component.$t("FORMS.INPUTS.EXTENDED_PROFILE_ALLOW_MEDIA")}`,
                          model: component.$v.form.allow_media,
                          submissionStates: component.submissionStates
                        }                    
                      },
                      {
                        show: component.form.system_answer_type_id == 1,
                        name: "InputGenericCheckbox",
                        properties: {
                          class: "col-xl-12 px-5 mb-0",
                          id: 'input-pre-populate-response',
                          label: `${component.$t("FORMS.INPUTS.EXTENDED_PROFILE_PRE_POP_TOGGLE")}`,
                          model: component.$v.pre_populate_toggle,
                          submissionStates: component.submissionStates
                        }
                      }
                    ]
                  },
                  {
                    show: component.form.system_answer_type_id == 1 && component.pre_populate_toggle == 1,
                    components: [
                      {
                        name: "InputGenericTextArea",
                        properties: {
                          class: "col-xl-12 px-5",
                          id: 'input-pre-populate-response-text',
                          label: `${component.$t("FORMS.INPUTS.EXTENDED_PROFILE_PRE_POP_TEXT")}`,
                          model: component.$v.form.pre_populate_response,
                          submissionStates: component.submissionStates,
                          rows: "6"
                        }
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            id: 2,
            name: `${component.$t("SETUP.EXTENDED_PROFILING.CHOICES")}`,
            show: component.form.system_answer_type_id == 2 || component.form.system_answer_type_id == 3,
            errors: component.customValidationErrors.tab2,
            sections: [
              {
                rows: [
                  {
                    components: [
                      {
                        name: "ExtendedProfileQuestionTable",
                        on: {
                          choice_removed: (value) => { component.$refs["extended-profile-question-topform"].$emit('choice_removed', value) },
                          choice_updated: (value) => { component.$refs["extended-profile-question-topform"].$emit('choice_updated', value) },
                          choice_added: (value) => { component.$refs["extended-profile-question-topform"].$emit('choice_added', value) },
                        },
                        properties: {
                          choices: component.form.choices,
                          validationTriggered: component.customValidationErrors.validationTriggered,
                          error: `${component.$t("FORMS.ERROR.EXTENDED_PROFILE_CHOICES")}`,
                          id: 'basic-profiling'
                        }
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
    }
  
  }
  
export default ManageExtendedProfileQuestion;