<template>
  <div class="row col-xl-12 mx-0 px-0">
    <!--start::TOPFORM-->
    <Topform
      ref="extended-profile-question-topform"
      :managementType="managementType"
      pageType="extended_profile_question"
      :name="extendedProfileQuestion.question | stripHTML"
      :errors="extendedProfileQuestionErrors"
      :submissionStates="submissionStates"
      parentPage="/project/setup/extended_profiling"
      :tabs="computedTabs"
      :validation="computedvalidation"
      :loadingComplete="loadingComplete"
      @clear_errors="clearErrors()"
      @submit="submit"
      @asset_reservation_created="form.stimuli.push($event)"
      @asset_reservation_deleted="removeAssetReservation($event)"
      @remove_project_asset="form.stimuli_remove.push($event)"
      @choice_removed="removeChoice($event)"
      @choice_updated="form.choices[$event.index] = $event.choice"
      @choice_added="addChoice($event)"
      @clear_existing_data="clearData"
    >
    </Topform>
    <!--end::TOPFORM-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import PageLoader from "@/core/classes/PageLoader.js";

import {
  POPULATE_EXTENDED_PROFILE_QUESTION,
  CLEAR_EXTENDED_PROFILE_QUESTION_ERRORS,
  CREATE_EXTENDED_PROFILE_QUESTION,
  UPDATE_EXTENDED_PROFILE_QUESTION,
  CLEAR_EXTENDED_PROFILE_QUESTION
} from "@/core/services/store/project/project_extended_profile_questions.module";

import { POPULATE_PROJECT_SEGMENTATION_LIST } from "@/core/services/store/project/project_segmentations.module"
import { POPULATE_PROJECT_SEGMENT_LIST } from "@/core/services/store/project/project_segments.module";

//Topform Imports
import TopformConfig from "@/core/config/topform/ManageExtendedProfileQuestion.js";

export default {
  mixins: [validationMixin],

  name: "ManageExtendedProfileQuestion",

  data() {
    return {
      tab: 1,
      pre_populate_toggle: 0,
      editIndex: null,
      segmentationBreakdown: [],
      error: {
        active: false,
        message: ""
      },
      customValidationErrors: {
        validationTriggered: false,
        tab2: null
      },
      pageLoader: new PageLoader(2),
      submissionStates: {
        submitting: false
      },
      form: {
        system_answer_type_id: 1,
        question: "",
        display: 0,
        required: 1,
        pre_populate_response: "",
        allow_media: 0,
        choices: [],
        stimuli: [],
        stimuli_remove: []
      }
    };
  },

  validations: {
    pre_populate_toggle: {},
    form: {
      system_answer_type_id: {
        required
      },
      question: {
        required
      },
      display: {
        required
      },
      required: {
        required
      },
      pre_populate_response: {},
      allow_media: {
        required
      },
    }
  },

  mounted() {
    this.fetchProjectSegmentations();
    this.fetchProjectSegments();
    
    if (this.managementType == "update") {
      this.pageLoader.addRequiredComponent();
      this.fetchExistingExtendedProfileQuestion();
    };
  },

  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    clearErrors: function() {
      this.$store.dispatch(CLEAR_EXTENDED_PROFILE_QUESTION_ERRORS);
    },
    fetchProjectSegmentations() {
      let payload = {
        projectId: this.projectInfo.id,
        params: {
          paginate: false
        }
      };
      this.$store
        .dispatch(POPULATE_PROJECT_SEGMENTATION_LIST, payload)
        .then(() => {
          this.pageLoader.setCompleteComponent();
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues retrieving the project segmentations list, please check back later or contact the helpdesk";
        });
    },
    fetchProjectSegments() {
      let payload = {
        projectId: this.projectInfo.id,
        params: {
          paginate: false
        }
      };
      this.$store
        .dispatch(POPULATE_PROJECT_SEGMENT_LIST, payload)
        .then(() => {
          this.pageLoader.setCompleteComponent();
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues retrieving the project segments list, please check back later or contact the helpdesk";
        });
    },
    fetchExistingExtendedProfileQuestion() {
      let payload = {
        projectId: this.projectInfo.id,
        id: this.$route.params.id,
        params: {
          extendedProfileChoices: 'with',
          projectAssets: 'with',
          appendSignedUrls: 1
        }
      }
      this.$store
        .dispatch(POPULATE_EXTENDED_PROFILE_QUESTION, payload)
        .then(() => {
          this.form.system_answer_type_id = this.extendedProfileQuestion.system_answer_type_id;
          this.form.question = this.extendedProfileQuestion.question;
          this.form.display = this.extendedProfileQuestion.display;
          this.form.required = this.extendedProfileQuestion.required;
          this.form.pre_populate_response = this.extendedProfileQuestion.pre_populate_response;
          this.form.allow_media = this.extendedProfileQuestion.allow_media;
          this.form.choices = this.extendedProfileQuestion.extended_profile_choices;
          if (this.form.pre_populate_response != null) {
            this.pre_populate_toggle = 1;
          }
          this.pageLoader.setCompleteComponent();
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues retrieving the extended profile question, please check back later or contact the helpdesk";
        });
    },
    clearData: function() {
      this.$store.dispatch(CLEAR_EXTENDED_PROFILE_QUESTION)
    },
    submit: function() {
      this.clearErrors();

      if (this.validateChoices() != true) {
        this.scrollTop();
        return;
      }

      this.submissionStates.submitting = true;

      if (this.managementType == "update") {
        this.submitUpdate();
      } else if (this.managementType == "post") {
        this.submitPost();
      }
    },
    submitUpdate: function() {
      let payload = {
        projectId: this.projectInfo.id,
        id: this.$route.params.id,
        data: this.form
      }

      this.$store
        .dispatch(UPDATE_EXTENDED_PROFILE_QUESTION, payload)
        .then(() => {
          this.$router.push({ name: 'setup_extended_profiling' });
        })
        .finally(() => {
          this.submissionStates.submitting = false;
        });
    },
    submitPost: function() {
      let payload = {
        projectId: this.projectInfo.id,
        data: this.form
      }

      this.$store
        .dispatch(CREATE_EXTENDED_PROFILE_QUESTION, payload)
        .then(() => {
          this.$router.push({ name: 'setup_extended_profiling' });
        })
        .finally(() => {
          this.submissionStates.submitting = false;
        });
    },
    validateChoices: function() {
      if (this.form.system_answer_type_id == 2 || this.form.system_answer_type_id == 3) {
        if (this.form.choices.length < 2) {
          this.customValidationErrors.validationTriggered = true;
          this.customValidationErrors.tab2 = "At least two choices are required";
          return false;
        } else {
          this.customValidationErrors.validationTriggered = false;
          this.customValidationErrors.tab2 = null;
          return true;
        }
      } else {
        this.customValidationErrors.validationTriggered = false;
        this.customValidationErrors.tab2 = null;
        return true;
      }
    },
    addChoice(value) {
      this.form.choices.push(value);

      if (this.customValidationErrors.validationTriggered) {
        this.validateChoices();
      }
    },
    removeChoice(value) {
      this.form.choices.splice(value, 1);

      if (this.customValidationErrors.validationTriggered) {
        this.validateChoices();
      }
    },
    removeAssetReservation($event) {
      this.form.attachments = this.form.attachments.filter(function(stim) {
        return stim !== $event;
      });
      this.form.previews = this.form.previews.filter(function(stim) {
        return stim.assetReservationId !== $event;
      });
    },
    scrollTop: function() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    }
  },

  computed: {
    ...mapGetters([
      "projectInfo",
      "extendedProfileQuestion",
      "extendedProfileQuestionErrors",
      "systemAnswerTypes"
    ]),
    assetReservationData: function() {
      return {
        scope: 'project',
        data: {
          directory: 'extended_profile_stimuli',
          projectId: this.projectInfo.id
        }
      }
    },
    loadingComplete: function() {
      return this.pageLoader.getStatus();
    },
    computedSystemAnswerTypes: function() {
      return this.systemAnswerTypes.filter(function( obj ) {
        return [1,2,3,4,6].includes(obj.id);
      });
    },
    managementType: function() {
      if (this.$route.params.id != undefined) {
        return "update";
      } else {
        return "post"
      }
    },
    tab1Errors: function() {
      if (
        this.$v.form.system_answer_type_id.$anyError ||
        this.$v.form.question.$anyError ||
        this.$v.form.display.$anyError ||
        this.$v.form.required.$anyError ||
        this.$v.form.pre_populate_response.$anyError ||
        this.$v.form.allow_media.$anyError
      ) {
        return true;
      } else {
        return false;
      }
    },
    computedvalidation: function() {
      return this.$v.form;
    },
    computedTabs: function() {
      return TopformConfig.topform(this);
    }
  }
};
</script>
